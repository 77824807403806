<div class="homepage-3">
    <div id="scrollUp" title="Deslocar para o topo">
        <i class="icofont-bubble-up"></i>
    </div>
    <div class="main">
        <app-header></app-header>
        <section id="home" class="section welcome-area bg-overlay d-flex align-items-center overflow-hidden">
            <div class="container">
                <div class="row align-items-center">
                    <!-- Welcome Intro Start -->
                    <div class="col-12 col-md-7 col-lg-6">
                        <div class="welcome-intro">
                            <h1 class="text-white">Gescalibra<br>Software para organismos de verificação Metrológica</h1>
                        </div>
                    </div>
                    <div class="col-12 col-md-5 col-lg-5">
                        <!-- Welcome Thumb -->
                        <div class="contact-box bg-white text-center rounded p-4 p-sm-5 p-md-4 p-lg-5 mt-5 mt-md-0 shadow-lg container-form-login">
                            <form class="inner-form text-left" [formGroup]="loginForm" (ngSubmit)="login()">
                                <div class="form-group">
                                    <div class="form-label d-flex justify-content-between">
                                        <label class="font-weight-bold" for="username">Utilizador:</label>
                                        <!-- <a class="color-primary" href="#" (click)="open(recoverUser)">Esqueceu o utilizador?</a> -->
                                    </div>
                                    
                                    <input type="text" class="form-control" id="username" aria-describedby="emailHelp" placeholder="Utilizador" formControlName="user" required="required" name="username">
                                </div>
                                <div class="form-group">
                                    <div class="form-label d-flex justify-content-between">
                                        <label  class="font-weight-bold" for="loginpass">Palavra-chave:</label>
                                        <a class="color-primary" href="#" (click)="open(recoverPassWord)">Esqueceu a Palavra-chave?</a>
                                    </div>
                                    <input type="password" class="form-control" id="loginpass" placeholder="Palavra-chave" formControlName="password" required="required">
                                </div>
                                <!-- <div class="form-group form-check">
                                    <input type="checkbox" class="form-check-input" id="exampleCheck1" formControlName="remenberme">
                                    <label class="form-check-label" for="exampleCheck1">Lembrar de mim</label>
                                </div> -->
                                <button type="submit" class="btn btn-login primary mt-4 btn-lg btn-block">Login</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Shape Bottom -->
            <div class="shape-bottom">
                <svg fill="#fff" id="Layer_1" x="0px" y="0px" viewBox="0 0 1921 819.8" style="enable-background:new 0 0 1921 819.8;" xml:space="preserve">
                    <path class="st0" d="M1921,413.1v406.7H0V0.5h0.4l228.1,598.3c30,74.4,80.8,130.6,152.5,168.6c107.6,57,212.1,40.7,245.7,34.4  c22.4-4.2,54.9-13.1,97.5-26.6L1921,400.5V413.1z"></path>
                    <path class="st1" d="M1921,387.9v431.9H0V0h13l226.6,594.4c14.4,35.7,34,67.4,58.3,94.3c24.4,26.9,54.2,49.8,88.6,68.1  c50.2,26.6,105.8,40.1,165.2,40.1c36.1,0,63.6-5.2,72.6-6.9c21.6-4,53.9-12.9,96.1-26.2L1921,387.9z"></path>
                </svg>
            </div>
        </section>
        <section id="benifits" class="section benifits-area style-two ptb_100">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-12 col-md-10 col-lg-12">
                        <!-- Section Heading -->
                        <div class="section-heading text-center">
                            <h2 class="text-capitalize">Gescalibra</h2>
                            <p class="d-none d-sm-block mt-4 text-left">O Gescalibra é a ferramenta ideal para a gestão de qualquer OVM, empresas de calibração ou de verificação dos mais variados equipamentos.
                            O Gescalibra foi desenvolvido assente nos mais recentes recursos tecnológicos com toda a experiência e conhecimentos da equipa Gobox,adquiridos ao longo dos anos.
                            Trata-se de uma solução completamente integrada, que oferece controlo absoluto sobre todos os processos operacionais, administrativos, financeiros e fiscais, tendo sempre em foco as peculiaridades dos diferentes processos de cada empresa.</p>
                        </div>
                    </div>
                </div>
                <div class="row benifits-content">
                    <!-- Benifits Item -->
                    <div class="col-12 col-sm-6 col-lg-3 p-0" data-aos="fade-up">
                        <div class="benifits-item text-center p-5">
                            <!-- Feature Icon -->
                            <div class="feature-icon mb-3">
                                <img src="assets/img/promo_5.png" alt="">
                            </div>
                            <!-- Benifits Text -->
                            <div class="benifits-text">
                                <h3 class="mb-2">Web Based</h3>
                                <p>O Gescalibra é um software de gestão de OVM totalmente cloud.</p>
                            </div>
                        </div>
                    </div>
                    <!-- Benifits Item -->
                    <div class="col-12 col-sm-6 col-lg-3 p-0" data-aos="fade-up" data-aos-delay="200">
                        <div class="benifits-item text-center p-5">
                            <!-- Feature Icon -->
                            <div class="feature-icon mb-3">
                                <img src="assets/img/promo_6.png" alt="">
                            </div>
                            <!-- Benifits Text -->
                            <div class="benifits-text">
                                <h3 class="mb-2">Interface intuitiva</h3>
                                <p>Facilidade de utilização, de forma a facilitar a introdução e consulta de informação.</p>
                            </div>
                        </div>
                    </div>
                    <!-- Benifits Item -->
                    <div class="col-12 col-sm-6 col-lg-3 p-0" data-aos="fade-up" data-aos-delay="400">
                        <div class="benifits-item text-center p-5">
                            <!-- Feature Icon -->
                            <div class="feature-icon mb-3">
                                <img src="assets/img/promo_7.png" alt="">
                            </div>
                            <!-- Benifits Text -->
                            <div class="benifits-text">
                                <h3 class="mb-2">Dados Seguros</h3>
                                <p>A segurança dos dados é garantida , por um sistema robusto de redundância de dados</p>
                            </div>
                        </div>
                    </div>
                    <!-- Benifits Item -->
                    <div class="col-12 col-sm-6 col-lg-3 p-0" data-aos="fade-up" data-aos-delay="400">
                        <div class="benifits-item text-center p-5">
                            <!-- Feature Icon -->
                            <div class="feature-icon mb-3">
                                <img src="assets/img/promo_8.png" alt="">
                            </div>
                            <!-- Benifits Text -->
                            <div class="benifits-text">
                                <h3 class="mb-2">Assistência</h3>
                                <p>Assistência permanente com uma equipa especializada sempre pronta a ajudar.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section id="features" class="section features-area ptb_100">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-12 col-md-10 col-lg-12">
                        <!-- Section Heading -->
                        <div class="section-heading text-center">
                            <h2 class="text-capitalize">Módulos e Funcionalidades</h2>
                            <p class="mt-4 text-left">O Gescalibra é um software cloud-based que simplifica todo o processo de verificação e calibração de tacógrafos, taxímetros, bancos de suspensão, instrumentos de pesagem e muitos outros, desde a marcação, inscrição/receção, procedimentos de verificação, revisão, comunicação com a AT, bem como procedimentos de gestão interna (Colaboradores, Equipamentos, Gestão da Qualidade), entre outros. Terá também acesso a vários menus de relatórios e estatísticas avançadas, relatórios para consultar a informação em qualquer lado, seja no computador, tablet ou smartphone.</p>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-md-6">
                        <div class="features-slider-wrapper">
                            <!-- Work Slider -->
                            <ul class="features-slider owl-carousel">
                                <li class="slide-item">
                                    <img src="assets/img/phone.jpg" alt="gescalibra imagem 1">
                                </li>
                                <li class="slide-item">
                                    <img src="assets/img/phone1.jpg" alt="gescalibra imagem 2">
                                </li>
                                <li class="slide-item">
                                    <img src="assets/img/phone2.jpg" alt="gescalibra imagem 2">
                                </li>
                                <li class="slide-item">
                                    <img src="assets/img/phone3.jpg" alt="gescalibra imagem 2">
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-12 col-md-6">
                        <ul class="features-item">
                            <li>
                                <!-- Icon Box -->
                                <div class="icon-box media icon-1 px-1 py-3 py-md-4">
                                    <!-- Icon -->
                                    <div class="icon rounded-circle border mr-3">
                                        <span><i class="fas fa-car"></i></span>
                                    </div>
                                    <!-- Icon Text -->
                                    <div class="icon-text media-body align-self-center align-self-md-start">
                                        <h3 class="mb-2">Processo de verificação / calibração</h3>
                                        <p>Simplificamos o processo de verificação e ou inspeção, desde a inscrição até a revisão de processos, os técnicos tem a possibilidade de validação de dados anexar fotos e outros documentos antes de finalizar o processo.</p>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <!-- Icon Box -->
                                <div class="icon-box media icon-2 px-1 py-3 py-md-4">
                                    <!-- Icon -->
                                    <div class="icon rounded-circle border mr-3">
                                        <span><i class="fas fa-user-tie"></i></span>
                                    </div>
                                    <!-- Icon Text -->
                                    <div class="icon-text media-body align-self-center align-self-md-start">
                                        <h3 class="mb-2">Colaboradores</h3>
                                        <p>Simplificamos vários processos: documental, integração, monitorização, monitorização extraordinária, avaliação, formações de colaboradores, avaliação e muito mais!
                                        </p>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <!-- Icon Box -->
                                <div class="icon-box media icon-3 px-1 py-3 py-md-4">
                                    <!-- Icon -->
                                    <div class="icon rounded-circle border mr-3">
                                        <span><i class="fas fa-tools"></i></span>
                                    </div>
                                    <!-- Icon Text -->
                                    <div class="icon-text media-body align-self-center align-self-md-start">
                                        <h3 class="mb-2">Equipamentos</h3>
                                        <p>Simplificamos o processo de gestão de equipamentos: inserção, ficha técnica, histórico, etiquetas, calibrações, intervenções, manutenções e muito mais!</p>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <!-- Icon Box -->
                                <div class="icon-box media icon-4 px-1 py-3 py-md-4">
                                    <!-- Icon -->
                                    <div class="icon rounded-circle border mr-3">
                                        <span><i class="fas fa-check-double"></i></span>
                                    </div>
                                    <!-- Icon Text -->
                                    <div class="icon-text media-body align-self-center align-self-md-start">
                                        <h3 class="mb-2">Qualidade</h3>
                                        <p>Simplificamos a gestão da qualidade e controlo documental: impressos, instruções de trabalho, legislação, ações preventivas, riscos de corrupção, auditorias entre outros documentos.</p>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
        <section class="section video-area bg-overlay ptb_100">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <div class="video-content text-center">
                            <!-- <h2 class="text-white mb-2">Veja aqui uma pequena apresentação</h2>
                            <h5 class="text-white">Dois minutos que podem simplificar a sua gestão</h5>
                            <div class="video-icon mt-4">
                                <a class="play-btn" data-fancybox href="https://www.youtube.com/watch?v=hs1HoLs4SD0">
                                    <div class="btn-circle play-animation"></div>
                                    <div class="btn-circle play-animation animation-short"></div>
                                    <div class="play-icon">
                                        <i class="fas fa-play"></i>
                                    </div>
                                </a>
                            </div> -->
                            <h1 class="text-white text-center mb-5 mt-5">O Gescalibra oferece-lhe as ferramentas necessárias para simplificar e automatizar a gestão do seu OVM.</h1>
                            <!-- <div class="row justify-content-center">
                                <div class="col-5 col-sm-3 text-center">
                                    <div class="single-counter d-inline-block m-2 m-sm-0">
                                        <div class="counter mb-3">
                                            <span class="p-2">19</span>
                                        </div>
                                        <h5>Centros</h5>
                                    </div>
                                </div>
                                <div class="col-5 col-sm-3 text-center">
                                    <div class="single-counter d-inline-block m-2 m-sm-0">
                                        <div class="counter mb-3">
                                            <span class="p-2">+180</span>
                                        </div>
                                        <h5>Utilizadores</h5>
                                    </div>
                                </div>
                                <div class="col-5 col-sm-3 text-center">
                                    <div class="single-counter d-inline-block m-2 m-sm-0">
                                        <div class="counter mb-3">
                                            <span class="p-2">+500000</span>
                                        </div>
                                        <h5>Inspeções/ano</h5>
                                    </div>
                                </div>
                                <div class="col-5 col-sm-3 text-center">
                                    <div class="single-counter d-inline-block m-2 m-sm-0">
                                        <div class="counter mb-3">
                                            <span class="p-2">+520000</span>
                                        </div>
                                        <h5>Veículos</h5>
                                    </div>
                                </div>
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- <section class="section video-area bg-overlay ptb_100">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <div class="video-content text-center">
                            <h2 class="text-white mb-2">Veja aqui uma pequena apresentação</h2>
                            <h5 class="text-white">Dois minutos que podem simplificar a sua gestão</h5>
                            <div class="video-icon mt-4">
                                <a class="play-btn" data-fancybox href="https://www.youtube.com/watch?v=hs1HoLs4SD0">
                                    <div class="btn-circle play-animation"></div>
                                    <div class="btn-circle play-animation animation-short"></div>
                                    <div class="play-icon">
                                        <i class="fas fa-play"></i>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section> -->
        <section class="section service-area ptb_100">
            <div class="shapes-container">
                <div class="shape-1"></div>
            </div>
            <div class="container">
                <div class="row">
                    <div class="col-12 col-lg-6 order-2 order-lg-1">
                        <!-- Service Text -->
                        <div class="service-text pt-4 pt-lg-0">
                            <h2 class=" mb-4 ges-advantages">Principais vantagens ao utilizar o GESCALIBRA</h2>
                            <!-- Check List -->
                            <ul class="check-list style-two">
                                <li class="py-1">
                                    <!-- List Box -->
                                    <div class="list-box media">
                                        <span class="icon align-self-center"><i class="fas fa-check-square"></i></span>
                                        <span class="media-body pl-3">Informação centralizada, com apenas um software um login, acede a todos os processos e a toda informação do seu centro ou centros de inspeção.</span>
                                    </div>
                                </li>
                                <li class="py-1">
                                    <!-- List Box -->
                                    <div class="list-box media">
                                        <span class="icon align-self-center"><i class="fas fa-check-square"></i></span>
                                        <span class="media-body pl-3">Software Web Based, a informação sempre acessível em qualquer parte do mundo. Permite poupar na aquisição de equipamentos, uma vez que não precisas de grandes recursos de hardware.</span>
                                    </div>
                                </li>
                                <li class="py-1">
                                    <!-- List Box -->
                                    <div class="list-box media">
                                        <span class="icon align-self-center"><i class="fas fa-check-square"></i></span>
                                        <span class="media-body pl-3">Custos controlados, as mensalidades são fixas a assistência está incluída na mensalidade.</span>
                                    </div>
                                </li>
                                <li class="py-1">
                                    <!-- List Box -->
                                    <div class="list-box media">
                                        <span class="icon align-self-center"><i class="fas fa-check-square"></i></span>
                                        <span class="media-body pl-3">Controlo e registo de entradas e saídas dos colaboradores, apenas podem realizar esse registo quando nas instalações do centro.</span>
                                    </div>
                                </li>
                                <li class="py-1">
                                    <!-- List Box -->
                                    <div class="list-box media">
                                        <span class="icon align-self-center"><i class="fas fa-check-square"></i></span>
                                        <span class="media-body pl-3">Defina as permissões por cargo de utilizador, decida de uma forma simples e rápida quem tem acesso à diferente informação.</span>
                                    </div>
                                </li>
                            </ul>
                            <a href="./assets/pdf/apresentacao_Gescalibra.pdf" target="_black" class="btn btn-demo primary mt-4">Descarregue a apresentação</a>
                        </div>
                    </div>
                    <div class="col-12 col-lg-4 order-1 order-lg-2 mx-auto">
                        <!-- Service Thumb -->
                        <div class="service-thumb style-two">
                            <img src="assets/img/service_thumb_1.png" alt="">
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section id="pricing" class="section price-plan-area ptb_100">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-12 col-md-10 col-lg-12">
                        <!-- Section Heading -->
                        <div class="section-heading text-center">
                            <h2 class="text-capitalize">Peça agora uma demonstração gratuitamente!</h2>
                            <p class="d-none d-sm-block mt-4">Faça já o seu pedido de demonstração, preenchendo o formulário abaixo.<br/>Os campos assinalados com * são obrigatórios.</p>
                        </div>
                    </div>
                </div>
                <div class="row justify-content-center">
                    <!-- Price Plan Wrapper -->
                    <div class="price-plan-wrapper">
                        <form class="contact-form contact-box text-left form-demo" [formGroup]="demoForm" (ngSubmit)="requestDemo()">
                            <div class="form-group">
                                <div class="form-divide-two">
                                    <div class="form-group">
                                        <label class="font-weight-bold" for="company">Empresa: <span class="requred">*</span></label>
                                        <input [ngClass]="{'input-error': !d.company.valid && d.company.touched}" type="text" class="form-control" id="company" placeholder="Empresa" formControlName="company">
                                    </div>
                                    <div class="input-form">
                                        <label  class="font-weight-bold" for="ovm">Centro de verificação/OVM: <span class="requred">*</span></label>
                                        <input [ngClass]="{'input-error': !d.ovm.valid && d.ovm.touched}" type="text" class="form-control" id="ovm" placeholder="Centro de verificação/OVM" formControlName="ovm">
                                    </div>
                                </div>
                                <div class="form-full-width">
                                    <div class="form-group">
                                        <label  class="font-weight-bold" for="address">Morada: <span class="requred">*</span></label>
                                        <input [ngClass]="{'input-error': !d.address.valid && d.address.touched}" type="text" class="form-control" id="address" placeholder="Morada" formControlName="address">
                                    </div>
                                </div>
                                <div class="form-divide-two">
                                    <div class="form-group">
                                        <label  class="font-weight-bold" for="street">Localidade: <span class="requred">*</span></label>
                                        <input [ngClass]="{'input-error': !d.street.valid && d.street.touched}" type="text" class="form-control" id="street" placeholder="Localidade" formControlName="street">
                                    </div>
                                    <div class="form-group">
                                        <label  class="font-weight-bold" for="postalcode">Código Postal: <span class="requred">*</span></label>
                                        <input [ngClass]="{'input-error': !d.postalcode.valid && d.postalcode.touched}" type="text" class="form-control" id="postalcode" placeholder="Código Postal" formControlName="postalcode">
                                    </div>
                                </div>
                                <div class="form-divide-two">
                                    <div class="form-group">
                                        <label  class="font-weight-bold" for="country">País: <span class="requred">*</span></label>
                                        <select id="country" formControlName="country" class="form-control select-country">
                                            <option *ngFor="let country of listOfCountries" [value]="country.id">
                                              {{country.select_show}}
                                            </option>
                                        </select>
                                        <!-- <input [ngClass]="{'input-error': !d.country.valid && d.country.touched}" type="text" class="form-control" id="country" placeholder="País" formControlName="country"> -->
                                    </div>
                                    <div class="form-group">
                                        <label  class="font-weight-bold" for="responsablename">Nome do responsável: <span class="requred">*</span></label>
                                        <input [ngClass]="{'input-error': !d.responsable.valid && d.responsable.touched}" type="text" class="form-control" id="responsablename" placeholder="Nome do responsável" formControlName="responsable">
                                    </div>
                                    
                                </div>
                                <div class="form-divide-two">
                                    <div class="form-group">
                                        <label  class="font-weight-bold" for="telephone">Telefone: <span class="requred">*</span></label>
                                        <input [ngClass]="{'input-error': !d.phone.valid && d.phone.touched}" type="text" class="form-control" id="telephone" placeholder="Telefone" formControlName="phone">
                                    </div>
                                    <div class="form-group">
                                        <label  class="font-weight-bold" for="email">Email: <span class="requred">*</span></label>
                                        <input [ngClass]="{'input-error': !d.email.valid && d.email.touched}" type="text" class="form-control" id="email" placeholder="Email" formControlName="email">
                                    </div>
                                </div>
                                <div class="form-full-width">
                                    <div class="form-group">
                                        <label  class="font-weight-bold">Mensagem:</label>
                                        <textarea class="form-control" name="message" formControlName="message" placeholder="Mensagem" required="required"></textarea>
                                    </div>
                                </div>
                                <div class="bntn-send-message">
                                    <button class="btn mt-3 mt-sm-4 btn-demo" type="submit">Pedir demonstração</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </section>
        <section class="section testimonial-area ptb_100" *ngIf="listOfTestimunials.length >= 1">
            <div class="container text-center">
                <div class="row justify-content-center align-items-center">
                    <div class="col-12 col-md-8">
                        <div class="testimonials owl-carousel">
                            <!-- Single Testimonial -->
                            <div class="single-testimonial p-3 p-md-5" *ngFor="let testim of listOfTestimunials">
                                <!-- Client Name -->
                                <h3 class="client-name mt-4 mb-2">{{testim.name}}</h3>
                                <!-- Client Address -->
                                <h5 class="client-address fw-4">{{testim.role}}</h5>
                                <!-- Client Rating -->
                                <!-- <div class="client-rating mt-2 mb-3">
                                    <ngb-rating [rate]="parseToInt(testim.grade)" [readonly]="true" [max]="5"></ngb-rating>
                                </div> -->
                                <!-- Client Description -->
                                <div class="client-description">
                                    <!-- Client Text -->
                                    <div class="client-text">
                                        <p>{{testim.description}}</p>
                                    </div>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
        </section>
        <section class="section map-area">
            <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11968.629125471318!2d-8.52695730006103!3d41.414104346202144!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x4dfb757c7c76b39f!2sGOBOX%2C%20Lda!5e0!3m2!1spt-PT!2spt!4v1640704520446!5m2!1spt-PT!2spt" width="100" height="100" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
        </section>
        <section id="contact" class="contact-area bg-gray ptb_100">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-12 col-md-10 col-lg-12">
                        <!-- Section Heading -->
                        <div class="section-heading text-center">
                            <h2 class="text-capitalize">Contactos</h2>
                            <p class="d-none d-sm-block mt-4">Podemos ajudar a esclarecer alguma questão? Entre em contacto connosco.</p>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <!-- Contact Box -->
                        <div class="contact-box">
                            <!-- Contact Form -->
                            <form [formGroup]="contactusForm" (ngSubmit)="sendEmaiContactUs()">
                                <div class="row">
                                    <div class="col-12 col-md-6">
                                        <h6 class="text-capitalize">Fale connosco:</h6>
                                        <div class="mt-5">
                                            <div class="cont-container">
                                                <div class="cont-icon">
                                                    <i class="fas fa-map-marker-alt"></i>
                                                </div>
                                                <div class="text">
                                                    <p>Av. Eng. Pinheiro Braga, n.º84, 4-D
                                                        4760-089 Vila Nova de Famalicão, Portugal</p>
                                                </div>
                                            </div>
                                            <div class="cont-container">
                                                <div class="cont-icon">
                                                    <i class="fas fa-envelope"></i>
                                                </div>
                                                <div class="text">
                                                    <p>info@gescalibra.pt</p>
                                                </div>
                                            </div>
                                            <div class="cont-container">
                                                <div class="cont-icon">
                                                    <i class="fas fa-phone"></i>
                                                </div>
                                                <div class="text">
                                                    <p style="margin-bottom: 0;">+351 919 481 290</p>
                                                    <p>+351 918 104 061</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 col-md-6">
                                        <div class="form-group">
                                            <input [ngClass]="{'input-error': !c.company.valid && c.company.touched}" type="text" class="form-control" name="name" placeholder="Empresa *" formControlName="company">
                                        </div>
                                        <div class="form-group">
                                            <input [ngClass]="{'input-error': !c.responsable.valid && c.responsable.touched}" type="text" class="form-control" name="responsable" placeholder="Nome do responsavel *" formControlName="responsable">
                                        </div>
                                        <div class="form-group">
                                            <input [ngClass]="{'input-error': !c.email.valid && c.email.touched}" type="text" class="form-control" name="email" placeholder="Email *" formControlName="email">
                                        </div>
                                        <div class="form-group">
                                            <input [ngClass]="{'input-error': !c.phone.valid && c.phone.touched}" type="text" class="form-control" name="phone" placeholder="Telefone" formControlName="phone">
                                        </div>
                                        <div class="form-group">
                                            <input [ngClass]="{'input-error': !c.subject.valid && c.subject.touched}" type="text" class="form-control" name="subject" placeholder="Assunto *" formControlName="subject">
                                        </div>
                                        <div class="form-group">
                                            <textarea [ngClass]="{'input-error': !c.message.valid && c.message.touched}" class="form-control" name="message" placeholder="Mensagem *" formControlName="message"></textarea>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="bntn-send-message">
                                            <button class="btn mt-3 mt-sm-4" type="submit">Enviar mensagem</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                            <p class="form-message"></p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <app-footer></app-footer>
    </div>
</div>

<ng-template #recoverPassWord let-modal>
    <div class="modal-body">
        <div class="login-area py-5 contact-box">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="modal-title">
                        <h4>Recuperar Palavra-chave</h4>
                    </div>
                    <div class="inner-content text-center inner-form">
                        <div class="form-group">
                            <div class="form-label d-flex justify-content-between">
                                <label  class="font-weight-bold" for="recoverpass">Nome de utilizador:</label>
                            </div>
                            <input type="text" [(ngModel)]="userRecover" class="form-control" id="recoverpass" placeholder="Nome de utilizador">
                        </div>
                        <button class="btn btn-login primary mt-4 btn-lg btn-block" (click)="recoverUserPass()">Recuperar</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #loginClientError let-modal>
    <div class="modal-body">
        <div class="login-area py-5 contact-box">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="modal-title mb-0">
                        <h5 class="error-msg" [innerHTML]="errorLoginMsg"></h5>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<div *ngIf="errorMessage" class="error-login">
    <div class="icon-msg">
        <i class="fas fa-times-circle"></i>
    </div>
    <P>{{messageError}}</P>
</div>
<div *ngIf="successMessage" class="success">
    <div class="icon-msg">
        <i class="fas fa-check-circle"></i>
    </div>
    <P>{{messageSuccess}}</P>
</div>

<!-- <re-captcha
    size="invisible"
    (resolved)="resolved($event)"
    siteKey="YOUR_SITE_KEY"
></re-captcha> -->